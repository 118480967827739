import React , { useEffect } from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
import Navbar from "../components/Navbar"
// ...GatsbyImageSharpFluid

const ProjectsPage = () => {
  const [navState, setNavState] = React.useState(false)


  return (
    <div>
         <Navbar  navState={navState} />
         <Projects setNavState={setNavState} />
    </div>
   

  )
  
  
}

export default ProjectsPage
