import React, { useEffect } from "react"
import logo from "../assets/logo.svg"
import MenuIcon from '@mui/icons-material/Menu';
import { FaTimes } from "react-icons/fa"
import PageLinks from "../constants/links"
import ProjectsPage from "../pages/projects"
import { useLocation } from '@reach/router';
import CloseIcon from '@mui/icons-material/Close';

const Navbar = (props) => {

  const [showNavbar, setShowNavbar] = React.useState(false)
  const [initNav, setInitNavbar] = React.useState(true)
  const [altNavColor, setAltNavColor] = React.useState(false);
  const [scrolledNav, setScrolledNav] = React.useState(false);
  const [opacity, setOpacity] = React.useState(0)
  const location = useLocation();


  const clickShowNav = () => {
    setShowNavbar(true)
    setInitNavbar(false)
  }
  const clickHideNav = () => setShowNavbar(false)

  useEffect(()=>{

    if(location.pathname=='/projects/' ){
      setAltNavColor(true)
    }

  },[location.pathname])

  useEffect(() => {
    const onScroll = () => {
      if (window.pageYOffset>10){
        setScrolledNav(true);
      }else{
        setScrolledNav(false);
      }
    }
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
}, []);




  return (
      <>
         <nav id={altNavColor?"altNav":""} className={props.navState?"navbar animate__fadeOutUp animate__animated":"navbar animate__fadeInDown animate__animated"}>
            
            
            <button type="button" className={scrolledNav?"toggle-btn scrolledNav":"toggle-btn"} onClick={clickShowNav}>
                  <MenuIcon></MenuIcon>
              </button>
            <PageLinks styleClass={ altNavColor ? "nav-links alt-links" : "nav-links"}>
              
            </PageLinks>
            

        </nav>
        <div className={showNavbar?"mobileNavWrapper animate__animated animate__fadeInRight":initNav?"mobileNavWrapper hide":"mobileNavWrapper  animate__animated animate__fadeOutRight"}>
          <div className="noiseNav ">
          
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
            <filter id="noise" x="0" y="0">
              <feTurbulence type="fractalNoise" baseFrequency="0.65" numOctaves="3" stitchTiles="stitch"/>
              <feBlend mode="screen"/>
            </filter>
            <rect width="100%" height="100%" filter="url(#noise)" opacity="0.5"/>
        </svg>
          </div>
          
          <button type="button" className="toggle-btn-close" onClick={clickHideNav}>
              <CloseIcon/>
          </button>
          <PageLinks styleClass={ altNavColor ? "nav-links alt-links" : "nav-links"}>
          
          </PageLinks>
    
        </div>    
      </>


  )

         
  


}

export default Navbar
