import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FaArrowUp } from "react-icons/fa"
import { GatsbyImage, getImage } from "gatsby-plugin-image"



const Projects = (props) => {
  const data = useStaticQuery(graphql`
    {
      allStrapiJob(sort: {fields: id}) {
        nodes {
          cliente
          description
          titulo
          stack
          url
          cover {
            childImageSharp {
              original {
                src
              }
              gatsbyImageData(
                placeholder: BLURRED
                
                formats: [AUTO, WEBP, AVIF]
                breakpoints:	[750, 1080, 1366, 1920]

              )
            }
          }
          media{
            url
          }
        }
      }
    }
    `
  ) 
  let p = data.allStrapiJob.nodes

  let hoverArray = []
  let clickArray = []
  {p.map(function(i,index){
    hoverArray.push(false);
    clickArray.push(false);
  })}

  const [cardHover, setCardHover] = React.useState(hoverArray)
  const [cardClick, setCardClick] = React.useState(clickArray)

  const setNavState = props.setNavState;

  const handleClick = (i) => {
    if (cardClick[i]==false){
      let newClick=[...clickArray]
      newClick[i]=true
      setCardClick(newClick)
      setNavState(true)
    }
  }
  const handleClickClose = (i) => {
    let newClick=[...clickArray]
    newClick[i]=false
    setCardClick(newClick)
    setNavState(false)
    let newJob=[...hoverArray]
    newJob[i]=false
    setCardHover(newJob)
  }

  const handleOnMouseOver = (i) => {
    let newJob=[...hoverArray]
    newJob[i]=true
    setCardHover(newJob)

  }
  const handleOnMouseOut = (i) => {
    let newJob=[...hoverArray]
    newJob[i]=false
    setCardHover(newJob)

  }
  
  

  return (
    <div className="projectsWrapper animate__fadeIn animate__animated " style={{overflow: "hidden"}}>
      <div className="noise"></div>
      <div className="subShapeWrapper">
              <span className='subShape1'></span>
              <span className='subShape2'></span>
              <span className='subShape3'></span>
            </div>
      <div  className={cardClick.some(elem => elem == true)? "projects-banner animate__fadeOut animate__animated" : "projects-banner animate__fadeIn animate__animated"}>
              <h1>PROJECTS · PROJECTS · PROJECTS · PROJECTS · PROJECTS · PROJECTS · PROJECTS · PROJECTS · PROJECTS · PROJECTS · PROJECTS · PROJECTS · PROJECTS · PROJECTS · PROJECTS · PROJECTS · PROJECTS ·</h1>
      </div>
      <div style={cardClick.some(elem => elem == true)? {margin:0,position:'fixed'}:{}}className='project-container animate__fadeInUp animate__animated'> 
          
          {p.slice(0).reverse().map(function(i,index){
            return (
              <div  onClick={()=> handleClick(index)}  onMouseOver={()=> handleOnMouseOver(index)}   onMouseOut={()=> handleOnMouseOut(index)}  
              className={(
                cardClick[index]? "project-card card-click " :(
                  cardClick.some(elem => elem == true)? "project-card  hide" :(
                    cardHover[index]? "project-card card-hover " : "project-card"
                  )
                )
              )}>
                    <div className={cardClick[index]? "info selected" : "info" }>
                   
                      <a className="project-card-close" onClick={()=> handleClickClose(index)} ><FaArrowUp className={cardClick[index]? "animate__fadeInUp  animate__animated" : "hide" } /></a>
                      <p className={cardClick[index]? " animate__fadeInUp animate__animated" : "hide" }>{i.description} </p>
                      {i.url &&
                        <a className={cardClick[index]? " animate__fadeInUp animate__animated" : "hide" } href={i.url}>Visit website </a>
                      }
                      <h2 >{i.cliente} </h2>
                      <h3 className={cardHover[index]? " " : "" }>{i.titulo} </h3>
                      <p className={cardClick[index]? " animate__fadeInUp animate__animated mobileJobDescription" : "hide" }>{i.description} </p>
                      <div className={cardClick[index]? "project-images animate__fadeInUp animate__animated" : "hide"}>
                        {i.media.map(function(e){
                          return <img src={e.url} />
                        })}
                      </div>
                      <div  className="vertical-data">
                        <div>{i.stack}</div>  
                        <div>{i.stack}</div>   
                      </div>
                    </div>
                    <div className={cardClick[index]? "hide" : "img-crop"}>
                      <GatsbyImage backgroundColor="#212121"  style={{height:"100%"}} image={i.cover.childImageSharp.gatsbyImageData} />
                    </div>    
              </div>
            )
          })}
      </div>
    </div>

    
  )
}
export default Projects
